@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #010026;
  @apply text-white;
}
body {
  overflow-x: hidden;
}
.bg-red {
  background-color: #010021 !important;
}
.text-red {
    font-family: 'russo one';
    font-weight: 400;
    color: #22d3d6f7!important;
}
.handbook{
    margin: 10px 0;
    font-size: 18px;
    color: #22d3d6f7!important;
    font-weight: bold;
}
.contact-title{
    font-size: 18px;
    margin: 10px 0;
    color: #22d3d6f7!important;
    font-weight: 900;
}
.contact-wrap{
  display: flex;
  width:50%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.contact-name{
  font-size: 20px;
  font-weight: bold;
}
.skills_section {
  width: 100%;
  height: 100vh;
  padding: 0px 13%;
  background-color: rgba(0, 0, 0, 0.082);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.skills_head {
  width: 100%;
  margin-bottom: 100px;
  display: grid;
  place-items: center;
  text-align: center;
}
.skills_head h2 {
  font-size: 30px;
  margin-bottom: -5px;
}
.skills_head h2 span {
  color: #dc4492;
}
.skills_main {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 30px;
  grid-column-gap: 50px;
}
.skills_main .skill_bar .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.skill_bar .info p:nth-child(2) {
  font-weight: 500;
}
.skill_bar .bar {
  width: 100%;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.144);
  border-radius: 25px;
  margin-top: 5px;
  position: relative;
}
.skill_bar .bar span {
  width: 50%;
  height: 100%;
  position: absolute;
  background-color: #2cbce9;
  border-radius: 25px;
}
.skill_bar .bar .html {
  width: 90%;
  animation: html 2s;
}
@keyframes html {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
.skill_bar .bar .css {
  width: 85%;
  animation: css 2s;
}
@keyframes css {
  0% {
    width: 0%;
  }
  100% {
    width: 85%;
  }
}
.skill_bar .bar .sass {
  width: 80%;
  animation: sass 2s;
}
@keyframes sass {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}
.skill_bar .bar .js {
  width: 80%;
  animation: css 2s;
}
@keyframes js {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}
.skill_bar .bar .react {
  width: 75%;
  animation: react 2s;
}
@keyframes react {
  0% {
    width: 0%;
  }
  100% {
    width: 75%;
  }
}
.skill_bar .bar .node {
  width: 70%;
  animation: node 2s;
}
@keyframes node {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
.skill_bar .bar .express {
  width: 65%;
  animation: express 2s;
}
@keyframes express {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}
.skill_bar .bar .mongo {
  width: 60%;
  animation: mongo 2s;
}
@keyframes mongo {
  0% {
    width: 0%;
  }
  100% {
    width: 60%;
  }
}
@media only screen and (max-width: 600px) {
  .skills_main {
    grid-template-columns: repeat(1, 1fr);
    /* grid-row-gap: 30px;
    grid-column-gap: 50px; */
  }
  .contact-wrap{
    display: block;
    width: 100%;
  }
  .contact-body{
    margin-bottom: 20px;
  }
  .countdown-value {
    width: 200px!important;
    padding: 40px 10px !important;
}
  
  
}
.custom-btn {
  margin-top: 40px;
  width: 250px;
  height: 60px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  font-size: 25px;
  font-weight: bold;
}
/* 11 */
.btn-11 {
  border: none;
  /* background: rgb(66, 33, 251);
  background: linear-gradient(
    0deg,
    rgba(66, 33, 251) 0%,
    rgb(44, 25, 153) 100%
  ); */
  color: #fff;
  overflow: hidden;
  background: linear-gradient(0deg, #1bb1b5, #52a4be);
  box-shadow: 0px 0px 14px 2px #21ccd0;
  color: #fff;
  font-family: 'poppins';
  overflow: hidden;
  margin-bottom: 20px;

}
.btn-11:hover {
  text-decoration: none;
  color: #fff;
}
.btn-11:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}
.btn-11:hover {
  opacity: 0.7;
}
.btn-11:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}




.timeline {
  position: relative;
  width: 660px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: ' ';
  display: block;
  width: 6px;
  height: 100%;
  margin-left: -3px;
  background: rgb(80,80,80);
  background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -webkit-gradiSent(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
  background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  
  z-index: 5;
}

.timeline li {
  padding: 1em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-l {
  position: relative;
  width: 300px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 300px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  
  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: rgb(248,248,248);
  padding: 6px 10px;
  border-radius: 5px;
  color:#010026;
  font-weight: 600;
  text-align: left;  
  font-size: 20px;

}

.direction-l .flag {
  -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}

/* .direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -40px;
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-top: -10px;
  background: #fff;
  border-radius: 10px;
  border: 4px solid rgb(255,80,80);
  z-index: 10;
} */

.direction-r .flag:before {
  left: -90px;

  
}
.direction-l .flag:before{
  right: -36px;
}
.direction-l .flag:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-left-color: rgb(248,248,248);
  border-width: 8px;
  pointer-events: none;
}

.direction-r .flag:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: rgb(248,248,248);
  border-width: 8px;
  pointer-events: none;
}

.time-wrapper {
  display: inline;
  
  line-height: 1em;
  font-size: 0.66666em;
  color: rgb(250,80,80);
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248,248,248);
}

.desc {
  margin: 1em 0.75em 0 0;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5em;
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
}

/* ================ Timeline Media Queries ================ */

@media screen and (max-width: 660px) {

.timeline {
 	width: 100%;
	padding: 4em 0 1em 0;
}

.timeline li {
	padding: 2em 0;
}

.direction-l,
.direction-r {
	float: none;
	width: 100%;

	text-align: center;
}

.flag-wrapper {
	text-align: center;
}

.flag {
	background: rgb(255,255,255);
	z-index: 15;
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: -30px;
	left: 50%;
	content: ' ';
	display: block;
	width: 12px;
	height: 12px;
	margin-left: -9px;
	background: #fff;
	border-radius: 10px;
	border: 4px solid rgb(255,80,80);
	z-index: 10;
}

.direction-l .flag:after,
.direction-r .flag:after {
	content: "";
	position: absolute;
	left: 50%;
	top: -8px;
	height: 0;
	width: 0;
	margin-left: -8px;
	border: solid transparent;
	border-bottom-color: rgb(255,255,255);
	border-width: 8px;
	pointer-events: none;
}

.time-wrapper {
	display: block;
	position: relative;
	margin: 4px 0 0 0;
	z-index: 14;
}

.direction-l .time-wrapper {
	float: none;
}

.direction-r .time-wrapper {
	float: none;
}

.desc {
	position: relative;
	margin: 1em 0 0 0;
	padding: 1em;
	background: rgb(245,245,245);
	-webkit-box-shadow: 0 0 1px rgba(0,0,0,0.20);
	-moz-box-shadow: 0 0 1px rgba(0,0,0,0.20);
	box-shadow: 0 0 1px rgba(0,0,0,0.20);
	color: #010026;
  z-index: 15;
}

.direction-l .desc,
.direction-r .desc {
	position: relative;
	margin: 1em 1em 0 1em;
	padding: 1em;
	
  z-index: 15;
}

}

@media screen and ( max-width: 660px) {

.direction-l .desc,
.direction-r .desc {
	margin: 1em 4em 0 4em;
}

}





.flip-clock-container {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 50px;
  line-height: 0;
}

.flip-clock-container * {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  cursor: default;
}

.flip-clock {
  display: flex;

  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
}

.digit {
  position: relative;

  width: 45px;
  height: 80px;

  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
}

.digit-left {
  margin-right: 1px;
}

.digit-right {
  margin-left: 1px;
}

.digit::before,
.digit::after {
  position: absolute;
  z-index: 0;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 50%;
  overflow: hidden;
}

.digit::before {
  content: attr(data-digit-before);

  bottom: 0;
  align-items: flex-start;
}

.digit::after {
  content: attr(data-digit-after);

  top: 0;
  align-items: flex-end;
}

.card {
  position: relative;
  z-index: 1;

  width: 100%;
  height: 50%;

  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  -webkit-transform-origin: bottom;
  -moz-transform-origin: bottom;
  transform-origin: bottom;

  -webkit-transform: rotateX(0);
  -moz-transform: rotateX(0);
  transform: rotateX(0);

  -webkit-transition: transform 0.7s ease-in-out;
  -moz-transition: transform 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out;
}

.card.flipped {
  transform: rotateX(-180deg);
}

.card-face {
  position: absolute;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  overflow: hidden;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-face-front {
  align-items: flex-end;
}

.card-face-back {
  align-items: flex-start;

  -webkit-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.digit::before,
.digit::after,
.card-face-front,
.card-face-back {
  background: white;
  color: black;
}

.digit,
.digit::before,
.digit::after,
.card,
.card-face {
  border-radius: 4px;
}

.digit::before,
.card-face-back {
  background: linear-gradient(0, #aaa, #ccc 50%, #fafafa);
  color: #212121;

  border-top: 1px solid black;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.digit::after,
.card-face-front {
  background: linear-gradient(0, #aaa, #bbb 50%, #f5f5f5);
  color: #212121;

  border-bottom: 1px solid black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.digit-left,
.digit-left::before,
.digit-left::after,
.digit-left .card,
.digit-left .card-face {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.digit-right,
.digit-right::before,
.digit-right::after,
.digit-right .card,
.digit-right .card-face {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.colon {
  color: black;
  margin: 0 6px;
  padding-bottom: 9px;
}
