.accordion-item {
  margin-bottom: 1rem;
  background-color: #010021!important;
  color: white!important;
}
.panel{
  color: white!important;
  border-bottom:1px solid #02011a!important ;
}
.css-i4bv87-MuiSvgIcon-root,.css-vubbuv{
  color:white!important
}
.details{
  color: wheat!important;
  font-weight: bold!important;
}